// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAchVW6Ia5A0lcG16dwQJI0ubVbN76s510",
  authDomain: "billnew1st.firebaseapp.com",
  databaseURL: "https://billnew1st-default-rtdb.firebaseio.com",
  projectId: "billnew1st",
  storageBucket: "billnew1st.appspot.com",
  messagingSenderId: "712411280250",
  appId: "1:712411280250:web:6a3faf55d3891ead631cc3",
  measurementId: "G-XNWMT6RK85"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };